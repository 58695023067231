// @flow

import * as React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Flex, Text, Button } from "@urbaninfrastructure/react-ui-kit";
import { Link, type NavigateFn } from "@reach/router";
import Countdown from "../Countdown";

import type { startTrip_startTrip } from "../../core.flow";

type Props = {
  expiresAt: ?Date,
  trip: startTrip_startTrip,
  navigate: ?NavigateFn
};

const CircleBox = styled(Flex)``;
const circleSize = "200px";
CircleBox.defaultProps = {
  justifyContent: "center",
  alignItems: "center",
  width: circleSize,
  height: circleSize,
  mx: "auto"
};

const PickUpBike = ({ navigate, expiresAt, trip }: Props) => {
  let name;
  let vehicleNumber;
  let dockNumber;
  const { userVehicleState, startDock, vehicle } = trip;
  if (vehicle) {
    name = vehicle.name;
    vehicleNumber = vehicle.name;
  }
  if (startDock) {
    dockNumber = startDock.number;
  }
  let message;
  let isVirtual;

  switch (userVehicleState) {
    case "awaiting_wake_event":
    case "awaiting_unlock_interaction": {
      message = (
        <FormattedMessage
          id="components.PickUpBike.pickBikeNrPressButton"
          defaultMessage="Press the button on{br} bike {name} from dock {number}"
          values={{ number: dockNumber, name, br: <br /> }}
        />
      );
      break;
    }
    case "awaiting_interaction_confirmation": {
      isVirtual = true;
      message = (
        <FormattedMessage
          id="components.PickUpBike.retrieveBikeNamed"
          defaultMessage="Press the button on the bike{br} {name} to start the trip"
          values={{ name, br: <br /> }}
        />
      );
      break;
    }
    default: {
      message = (
        <FormattedMessage
          id="components.PickUpBike.pickBikeNr"
          defaultMessage="Pick up bike {br} {name} from dock {number}"
          values={{ number: dockNumber, name, br: <br /> }}
        />
      );
    }
  }

  return (
    <React.Fragment>
      {!isVirtual && (
        <>
          <CircleBox bg="primary" color="white" my="md" borderRadius="50%">
            <Text
              fontSize={120}
              fontWeight="bold"
              lineHeight={0}
              data-testid="PickUpBike__Number"
            >
              {dockNumber}
            </Text>
          </CircleBox>
          <Text typoStyle="lg" mb="md">
            {name}
          </Text>
        </>
      )}
      {isVirtual && (
        <>
          <Text typoStyle="xxl" mb="xs">
            {name}
          </Text>
          <Text typoStyle="lg" mb="md">
            {vehicleNumber}
          </Text>
        </>
      )}
      <Text typoStyle="sm" mb="lg">
        {message}
      </Text>
      {expiresAt && (
        <Countdown
          date={expiresAt}
          render={({ minutes, seconds, completed }) => {
            if (completed) {
              navigate && navigate("../");
            }
            return null;
          }}
        />
      )}
      <Link to="../" data-testid="PickUpBike__ExitButton">
        <Button as="div" jumbo>
          <FormattedMessage
            id="components.PickUpBike.exit"
            defaultMessage="Exit"
          />
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default PickUpBike;
