// @flow

import styled from "styled-components";
import { Box } from "@urbaninfrastructure/react-ui-kit";

const ContentBox = styled(Box)`
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  max-width: 800px;
`;

ContentBox.defaultProps = {
  bg: "white",
  color: "text",
  mx: "auto",
  p: 6,
  borderRadius: "md",
  width: 1,
  textAlign: "center"
};

export default ContentBox;
