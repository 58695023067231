// @flow

import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import { minWidth } from "styled-system";
import { Box, type Theme } from "@urbaninfrastructure/react-ui-kit";

type Props = {
  type?: "password",
  focused?: boolean,
  children?: React.Node,
  value?: string
};

type StyledProps = Props & {
  theme: Theme
};

const blinkAnimation = keyframes`
  0%, 20%, 75%, 100% {
    opacity: 0;
  }
  30%, 70% {
    opacity: 1;
  }
`;

const focusAnimation = keyframes`
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: white;
  }
`;

const StyledKeyboardInput = styled(Box)`
  ${minWidth};
  border: 1px solid ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  height: 5rem;
  line-height: 1.25;
  ${({ focused }): StyledProps =>
    focused &&
    css`
      animation: ${focusAnimation} 250ms linear;
      animation-iteration-count: 1;
      will-change: background-color;
      &:after {
        content: "_";
        display: inline-block;
        animation: ${blinkAnimation} 1000ms linear;
        animation-iteration-count: infinite;
        will-change: opacity;
      }
    `};
`;

StyledKeyboardInput.defaultProps = {
  p: 4,
  color: "text",
  bg: "transparent",
  borderRadius: "md",
  textAlign: "left"
};

StyledKeyboardInput.displayName = "StyledKeyboardInput";

const KeyboardInput = ({ value, children, ...props }: Props) => {
  let inputValue = value || children;
  if (typeof inputValue === "string" && props.type === "password") {
    inputValue = new Array(inputValue.length).fill("•");
  }
  return (
    <StyledKeyboardInput data-testid="KeyboardInput" {...props}>
      {inputValue}
    </StyledKeyboardInput>
  );
};

export default KeyboardInput;
