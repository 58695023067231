// @flow

import * as React from "react";
import styled from "styled-components";
import { Box } from "@urbaninfrastructure/react-ui-kit";
import { Warning as WarningIcon } from "@urbaninfrastructure/react-icons";
import { motion, AnimatePresence } from "framer-motion";

const Wrapper = styled(Box)``;

type NotificationType = "error" | "info";

const Notification = ({ type, ...props }: { type: NotificationType }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0 }}
      {...props}
    />
  );
};

Notification.defaultProps = {
  type: "info"
};

type Props = {
  notifications: Array<{ message: React.Node, type?: NotificationType }>
};

const Notifications = ({ notifications }: Props) => {
  return (
    <Wrapper color="white" textAlign="center" fontSize={3}>
      <motion.div
        animate={{ scale: notifications.length === 0 ? 0 : 1 }}
        exit={{ scale: 0 }}
        initial={{ scale: 0 }}
        pose={notifications.length === 0 ? "exit" : "enter"}
      >
        <WarningIcon size={150} />
      </motion.div>
      <AnimatePresence>
        {notifications.map(({ message, ...notification }, i) => {
          return (
            <Notification key={i} {...notification}>
              {message}
            </Notification>
          );
        })}
      </AnimatePresence>
    </Wrapper>
  );
};

Notifications.defaultProps = {
  notifications: []
};

export default Notifications;
