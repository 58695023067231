// @flow

import * as React from "react";
import { FormattedMessage, FormattedTime } from "react-intl";
import { Flex, Box, Heading, Text } from "@urbaninfrastructure/react-ui-kit";
import { Button, Link, Logo, Closed, BlockContent } from "../components";

import type { RouteProps } from "@reach/router";
import type { bootstrap_homeScreen, bootstrap_system } from "../core.flow";

type Props = RouteProps & {
  system: bootstrap_system,
  homeScreen: ?bootstrap_homeScreen,
  haveAboutScreen: ?boolean,
  locale: string,
  kioskId: string
};

function Home({
  system,
  homeScreen,
  haveAboutScreen,
  location,
  locale,
  kioskId
}: Props) {
  if (!location) {
    throw new Error("Missing location");
  }
  if (location.search === "?__throw_error=2") {
    throw new Error("Oops 2");
  }

  // Scroll to top
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  const { workingHours, inSeason } = system;
  const isOpen = workingHours.insideOpeningHours && inSeason;
  const timeProps = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  };
  return (
    <Flex
      data-testid="HomeScreen"
      flexDirection="column"
      justifyContent="center"
      flex={2}
      px={7}
      pt={7}
      height="100%"
    >
      <Flex justifyContent="center" flexDirection="column" flex={1}>
        <Box mb={6}>
          <Logo width="25vh" height="25vw" />
        </Box>
        {homeScreen && (
          <>
            {homeScreen.heading && (
              <Heading data-testid="HomeScreen__Heading">
                {homeScreen.heading}
              </Heading>
            )}
            {!homeScreen.publicServiceAnnouncement && (
              <Text as="p" mb="md" fontSize={3}>
                <FormattedMessage
                  id="pages.Home.gettingStarted"
                  defaultMessage="Get started on your phone. Download {systemName} in the App Store or on Google Play."
                  values={{ systemName: system.name }}
                />
              </Text>
            )}
            {homeScreen.publicServiceAnnouncement && (
              <Text mb="md" fontSize={3} px="xs">
                <BlockContent
                  systemId={system.id}
                  locale={locale}
                  kioskId={kioskId}
                  blocks={homeScreen.publicServiceAnnouncement}
                />
              </Text>
            )}
          </>
        )}

        <Box mb="sm" mr="xs">
          <Link
            to="app"
            data-testid="HomeScreen__AppPageLink"
            style={{ display: "inline-block" }}
          >
            <Text color="white">
              <Button variant="primary" as="span" jumbo>
                <FormattedMessage
                  id="pages.Home.app"
                  defaultMessage="Get the app"
                />
              </Button>
            </Text>
          </Link>
        </Box>
      </Flex>
      <Box>
        <Text mb="md" fontSize={4}>
          <Closed workingHours={workingHours} inSeason={inSeason} />
        </Text>

        <Flex justifyContent="center" flexWrap="wrap" mb="sm">
          <Box mr={5}>
            <Link to="how-it-works" data-testid="HomeScreen__HowItWorksLink">
              <Text fontSize={3} color="white" mb="sm">
                <Button as="span">
                  <FormattedMessage
                    id="pages.Home.howItWorks"
                    defaultMessage="How it works"
                  />
                </Button>
              </Text>
            </Link>
          </Box>
          <Box mr={5}>
            <Link to="map" data-testid="HomeScreen__StationMapLink">
              <Button as="span">
                <FormattedMessage
                  id="pages.Home.stationMap"
                  defaultMessage="Station map"
                />
              </Button>
            </Link>
          </Box>
          {isOpen && (
            <Box mb="sm" mr={5}>
              <Link to="start-trip" data-testid="HomeScreen__StartTripLink">
                <Button as="span">
                  <FormattedMessage
                    id="pages.Home.unlockButton"
                    defaultMessage="Unlock with code"
                  />
                </Button>
              </Link>
            </Box>
          )}
          {haveAboutScreen && (
            <Box>
              <Link to="about" data-testid="HomeScreen__AboutLink">
                <Button as="span">
                  <FormattedMessage
                    id="pages.Home.about"
                    defaultMessage="About"
                  />
                </Button>
              </Link>
            </Box>
          )}
        </Flex>
        {inSeason && workingHours.startDate && workingHours.endDate && (
          <FormattedMessage
            id="pages.Home.openingHours"
            defaultMessage="Our opening hours are from {startTime} to {endTime}."
            values={{
              startTime: (
                <FormattedTime value={workingHours.startDate} {...timeProps} />
              ),
              endTime: (
                <FormattedTime value={workingHours.endDate} {...timeProps} />
              )
            }}
          />
        )}
      </Box>
    </Flex>
  );
}

export default Home;
