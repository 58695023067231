// @flow

import * as React from "react";
import styled from "styled-components";
import { Box, type Theme } from "@urbaninfrastructure/react-ui-kit";
import EraseIcon from "./EraseIcon";

const EMPTY = "EMPTY";
const BACKSPACE_KEY_CODE = "Backspace";
const KEYS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  EMPTY,
  "0",
  BACKSPACE_KEY_CODE
];
const KEY_SIZE = 60;

type KeyboardButtonProps = {
  theme: Theme,
  gridColumnEnd: string,
  isBackspace: boolean
};

const KeyboardButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 50%;
  margin: 0;
  padding: 0;

  margin: auto;

  width: 6rem;
  height: 6rem;

  background-color: ${({ theme, isBackspace }: KeyboardButtonProps) =>
    isBackspace ? "white" : theme.colors.neutral[2]};
  color: ${({ theme }: KeyboardButtonProps) => theme.colors.text};

  grid-column-end: ${({ gridColumnEnd }: KeyboardButtonProps) => gridColumnEnd};
  grid-row-end: span 2;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 32px;
  font-family: ${({ theme }: KeyboardButtonProps) => theme.fonts.button};

  &:focus {
    outline: 0;
  }

  &:active {
    background-color: white;
    color: ${({ theme }: KeyboardButtonProps) => theme.colors.primary};
  }
`;

KeyboardButton.displayName = "KeyboardButton";

KeyboardButton.defaultProps = {
  type: "button"
};

const KeyboardLayout = styled(Box)`
  display: grid;

  grid-template-columns: repeat(3, ${KEY_SIZE * 2}px);
  grid-template-rows: repeat(8, ${KEY_SIZE}px);

  grid-gap: 5px;
  justify-content: center;
`;

KeyboardLayout.displayName = "KeyboardLayout";

type KeyboardProps = {
  onButtonClick: (keyCode: string) => void
};

const Keyboard = ({ onButtonClick, ...props }: KeyboardProps) => {
  return (
    <KeyboardLayout {...props}>
      {KEYS.map(key => {
        const isBackspace = key === BACKSPACE_KEY_CODE;
        const isEmpty = key === EMPTY;
        if (isEmpty) {
          return <div key={key} />;
        }
        return (
          <KeyboardButton
            key={key}
            gridColumnEnd={"span 1"}
            data-testid={`KeyboardButton__${key}`}
            isBackspace={isBackspace}
            onClick={event => {
              event.preventDefault();
              onButtonClick(key);
            }}
          >
            {isBackspace ? <EraseIcon width={75} height={75} /> : key}
          </KeyboardButton>
        );
      })}
    </KeyboardLayout>
  );
};

export default Keyboard;
