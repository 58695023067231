// @flow

import * as React from "react";
import * as Sentry from "@sentry/browser";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import IdleTimer from "react-idle-timer";
import qs from "query-string";
import { Router, navigate } from "@reach/router";
import styled from "styled-components";
import {
  Home,
  Activate,
  StartTrip,
  HowItWorks,
  StationMap,
  AboutScreen,
  AppScreen
} from "./pages";
import {
  Header,
  ErrorBoundary,
  BaseStyles,
  GenericErrorComponent,
  DebugInfo,
  Box
} from "./components";
import { BootstrapQuery } from "./lib/queries";

import enMessages from "./lang/en.json";
import nbMessages from "./lang/nb.json";

const messages = {
  en: enMessages,
  nb: nbMessages
};

const DEFAULT_LOCALE = "en";

const Wrapper = styled(Box)`
  @media (orientation: landscape) {
    max-width: 60vw;
  }
`;

Wrapper.defaultProps = {
  "data-testid": "App_Wrapper",
  color: "white",
  height: "100vh",
  textAlign: "center",
  mx: "auto"
};

const Content = styled(Box)`
  overflow-y: auto;
`;
Content.defaultProps = {
  width: 1,
  height: "90vh"
};

const NoMatch = () => {
  return (
    <Wrapper color="text">
      <Content>Page not found!</Content>
    </Wrapper>
  );
};

const log = console.log;
const debug = console.debug;

function AppReloader({ rootUrl, reloadTimeout }) {
  const idleTimerRef = React.useRef(null);
  const [checkVersion, setCheckVersion] = React.useState(null);
  const [currentVersion, setCurrentVersion] = React.useState(
    process.env.REACT_APP_VERSION
  );
  React.useEffect(() => {
    let didCancel = false;
    if (checkVersion) {
      async function checkManifest() {
        try {
          const res = await fetch(`/manifest.json?${checkVersion}`);
          const manifest = await res.json();
          if (currentVersion && manifest.version !== currentVersion) {
            const newUrl = `${window.location.origin}${rootUrl}`;
            log(
              `Version changed from ${currentVersion} to ${manifest.version}, redirecting to ${newUrl}`
            );
            window.location.href = newUrl;
          } else {
            debug("Version not changed, skipping reload.");
          }
          if (!didCancel) {
            setCurrentVersion(manifest.version);
          }
        } catch (err) {
          console.error(err);
        } finally {
          if (!didCancel) {
            if (idleTimerRef.current) {
              idleTimerRef.current.reset();
            }
            setCheckVersion(null);
          }
        }
      }
      checkManifest();
    }

    return () => {
      didCancel = true;
    };
  }, [checkVersion, currentVersion, rootUrl]);
  return (
    <IdleTimer
      ref={idleTimerRef}
      onIdle={() => {
        setCheckVersion(new Date().getTime());
      }}
      debounce={250}
      timeout={reloadTimeout.current}
    />
  );
}

if (!process.env.REACT_APP_IDLE_RELOAD_TIMEOUT_SECONDS) {
  throw new Error("process.env.REACT_APP_IDLE_RELOAD_TIMEOUT_SECONDS not set");
}

if (!process.env.REACT_APP_IDLE_RESET_TIMEOUT_SECONDS) {
  throw new Error("process.env.REACT_APP_IDLE_RESET_TIMEOUT_SECONDS not set");
}

if (!process.env.REACT_APP_START_TRIP_TIMEOUT) {
  throw new Error("process.env.REACT_APP_START_TRIP_TIMEOUT not set");
}

function StationClient({
  systemId,
  kioskId,
  setLocale,
  setDefaultLocale,
  location,
  ...props
}) {
  const intl = useIntl();
  const query: {
    __resetTimeout?: string,
    __reloadTimeout?: string,
    __startTripTimeout?: string,
    __throw_error?: string
  } = qs.parse(location.search);
  const resetTimeout = React.useRef(
    query.__resetTimeout
      ? parseInt(query.__resetTimeout)
      : parseInt(process.env.REACT_APP_IDLE_RESET_TIMEOUT_SECONDS) * 1000
  );
  const reloadTimeout = React.useRef(
    query.__reloadTimeout
      ? parseInt(query.__reloadTimeout)
      : parseInt(process.env.REACT_APP_IDLE_RELOAD_TIMEOUT_SECONDS) * 1000
  );
  const startTripTimeout = React.useRef(
    query.__startTripTimeout
      ? parseInt(query.__startTripTimeout)
      : parseInt(process.env.REACT_APP_START_TRIP_TIMEOUT)
  );
  React.useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: kioskId,
        systemId
      });
    });
  }, [kioskId, systemId]);
  if (query.__throw_error === "1") {
    throw new Error("Oops");
  }
  const { locale, defaultLocale } = intl;
  // $FlowFixMe
  const isRoot = props["*"] === "";
  const rootUrl = `/${systemId}/${kioskId}`;
  return (
    <>
      <IdleTimer
        onIdle={() => {
          // redirect to root when idle
          if (!isRoot) {
            debug("Redirecting to root");
            navigate(rootUrl);
          }
        }}
        debounce={250}
        timeout={resetTimeout.current}
      />
      <AppReloader rootUrl={rootUrl} reloadTimeout={reloadTimeout} />
      <BootstrapQuery
        variables={{
          systemId,
          kioskId,
          locale
        }}
        pollInterval={1000 * 30}
      >
        {({ data, loading, error, refetch, client }) => {
          const colourKey = data && data.system && data.system.colourKey;

          if (
            data &&
            data.system &&
            data.system.defaultLanguageCode &&
            data.system.defaultLanguageCode !== defaultLocale
          ) {
            setDefaultLocale(data.system.defaultLanguageCode);
          }

          if (loading && (!data || !data.system)) {
            return (
              <BaseStyles colourKey={colourKey}>
                <Wrapper>
                  <FormattedMessage
                    id="App.loading"
                    defaultMessage="Loading …"
                  />
                </Wrapper>
              </BaseStyles>
            );
          }

          if (error && !data) {
            return <GenericErrorComponent colourKey={colourKey} />;
          }

          if (!data) {
            throw new Error("Could not load data");
          }
          if (!data.system) {
            throw new Error("Could not load system");
          }
          const { system, stationClient, homeScreen, haveAboutScreen } = data;
          const showActivateScreen = !stationClient;

          return (
            <BaseStyles colourKey={colourKey}>
              <Wrapper>
                <ErrorBoundary
                  systemId={systemId}
                  kioskId={kioskId}
                  colourKey={colourKey}
                  skipProviders={true}
                >
                  {showActivateScreen && (
                    <Content>
                      <Activate
                        kioskId={kioskId}
                        onActivated={() => {
                          refetch();
                        }}
                      />
                    </Content>
                  )}
                  {!showActivateScreen && (
                    <>
                      {/* $FlowFixMe */}
                      <Router component={Content} primary={false}>
                        <Home
                          path="/"
                          homeScreen={homeScreen}
                          haveAboutScreen={haveAboutScreen}
                          system={system}
                          locale={locale}
                          kioskId={kioskId}
                        />
                        <HowItWorks
                          path="how-it-works"
                          systemId={systemId}
                          locale={locale}
                          kioskId={kioskId}
                        />
                        {/* $FlowFixMe type reach router? */}
                        <StartTrip
                          path="start-trip"
                          kioskId={kioskId}
                          systemId={systemId}
                          system={data.system}
                          startTripTimeout={startTripTimeout.current}
                        />
                        {stationClient && stationClient.dockGroup && (
                          <StationMap
                            path="map"
                            height="100%"
                            dockGroup={stationClient.dockGroup}
                            kioskId={kioskId}
                            system={data.system}
                          />
                        )}
                        <AboutScreen
                          path="about"
                          systemId={systemId}
                          kioskId={kioskId}
                          locale={locale}
                        />
                        <AppScreen
                          path="app"
                          system={system}
                          kioskId={kioskId}
                          locale={locale}
                        />
                      </Router>
                      <Header
                        showBackButton={!isRoot}
                        availableLocales={system.languageCodes}
                        onLanguageClick={newLocale => {
                          setLocale(newLocale);
                        }}
                      />
                    </>
                  )}
                </ErrorBoundary>
              </Wrapper>
              <DebugInfo kioskId={kioskId}></DebugInfo>
            </BaseStyles>
          );
        }}
      </BootstrapQuery>
    </>
  );
}

export default function App() {
  const [defaultLocale, setDefaultLocale] = React.useState(DEFAULT_LOCALE);
  const [locale, setLocale] = React.useState();

  return (
    <IntlProvider
      locale={locale || defaultLocale}
      defaultLocale={defaultLocale}
      messages={messages[locale || defaultLocale]}
    >
      <ErrorBoundary colourKey={null} systemId={null} kioskId={null}>
        <Router>
          {/* $FlowFixMe type reach router? */}
          <StationClient
            path="/:systemId/:kioskId/*"
            setLocale={setLocale}
            setDefaultLocale={setDefaultLocale}
          />
          <NoMatch default />
        </Router>
      </ErrorBoundary>
    </IntlProvider>
  );
}
