// @flow

import { print } from "graphql/language/printer";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import * as Sentry from "@sentry/browser";
import env from "./env";

if (!env.backendHost) {
  throw new Error("backend host not found");
}

const getGraphiQLUrl = (coreEndpoint: string, operation: ?Object): ?string => {
  if (!operation) {
    return null;
  }
  const params = [];
  Object.keys(operation).forEach(key => {
    if (!operation || !operation[key]) {
      return;
    }
    const value =
      key === "query" ? print(operation[key]) : JSON.stringify(operation[key]);
    params.push(`${key}=${encodeURIComponent(value)}`);
  });
  return `${coreEndpoint}/station-client/graphql/explore?${params.join("&")}`;
};

const errorLink = onError(error => {
  const { graphQLErrors, networkError, operation } = error;
  if (graphQLErrors || networkError) {
    if (graphQLErrors) {
      const graphiqlUrl = getGraphiQLUrl(env.backendHost, operation);
      graphQLErrors.forEach((error: Core$GraphQLError) => {
        const { code, message } = error;
        const errorMessage = `[GraphQL error]: ${code}: ${message}`;
        Sentry.withScope(scope => {
          scope.setFingerprint([
            "Type: GraphQLError",
            `Code: ${code}`,
            `Message: ${message}`
          ]);
          scope.setExtra("graphiqlUrl", graphiqlUrl);
          if (networkError) {
            scope.setExtra("networkError", networkError);
          }
          Sentry.captureException(new Error(errorMessage));
        });
      });
    } else if (networkError) {
      let message = `[Network error]: ${networkError}`;
      if (operation && operation.operationName) {
        message += `, operationName: ${operation.operationName}`;
      }
      Sentry.withScope(scope => {
        scope.setFingerprint(["Type: GraphQLNetworkError"]);
        Sentry.captureException(new Error(message));
      });
    }
  }
});

const httpLink = new HttpLink({
  uri: env.backendHost + "/station-client/api/v1/graphql"
});

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    const id = object.id || object._id;
    switch (object.__typename) {
      case "ArticlePage":
      case "FAQEntry":
        return `${id}:${object.locale}`;
      default:
        return id;
    }
  }
});

const link = ApolloLink.from([errorLink, httpLink]);
const storage = window.localStorage;

export const waitOnCache = persistCache({ cache, storage });

export const client = new ApolloClient<{}>({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all"
    },
    query: {
      errorPolicy: "all"
    }
  }
});
