// @flow

import gql from "graphql-tag";

export const ABOUT_SCREEN_QUERY = gql`
  query aboutScreen($systemId: ID!, $locale: String!) {
    aboutScreen(systemId: $systemId, locale: $locale) {
      heading
      content
      faqs {
        _id
        _updatedAt
        question
        answer
        locale
      }
    }
  }
`;
