// @flow

import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Heading, Alert, Box, BlockContent, Text } from "../components";
import { HowItWorksQuery } from "../lib/queries";

type Props = {
  systemId: string,
  locale: string,
  kioskId: string
};

export default function HowItWorks({ systemId, locale, kioskId }: Props) {
  return (
    <HowItWorksQuery
      fetchPolicy="cache-and-network"
      variables={{ systemId, locale }}
    >
      {({ data, loading, error, variables }) => {
        if (loading && (!data || !data.articlePageBySlug)) {
          return (
            <p>
              <FormattedMessage
                id="HowItWorks.loading"
                defaultMessage="Loading how it works"
              />
            </p>
          );
        }
        const articlePage = data && data.articlePageBySlug;
        if (!articlePage) {
          return <Alert>Could not find page</Alert>;
        }
        return (
          <Box px={7} pt={7}>
            <Heading mt="sm" data-testid="HowItWorks__Heading">
              {articlePage.title}
            </Heading>
            <Text textAlign="left">
              <Text typoStyle="sm">
                <BlockContent
                  systemId={systemId}
                  locale={locale}
                  kioskId={kioskId}
                  blocks={articlePage.intro}
                />
              </Text>
              <BlockContent
                systemId={systemId}
                locale={locale}
                kioskId={kioskId}
                blocks={articlePage.content}
              />
            </Text>
          </Box>
        );
      }}
    </HowItWorksQuery>
  );
}
