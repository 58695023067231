// @flow

import * as React from "react";
import addSeconds from "date-fns/addSeconds";
import { Flex, StartTripForm, ContentBox, PickUpBike } from "../components";

import type { NavigateFn } from "@reach/router";
import type { bootstrap_system } from "../core.flow";

type Props = {
  kioskId: string,
  systemId: string,
  system: bootstrap_system,
  navigate: NavigateFn,
  startTripTimeout: number
};

export default function StartTrip({
  system,
  kioskId,
  systemId,
  navigate,
  startTripTimeout
}: Props) {
  const [trip, setTrip] = React.useState();

  if (trip) {
    if (!trip.startDock || !trip.startDock.number) {
      throw new Error("Something went wrong");
    }
    const expiresAt = addSeconds(new Date(), startTripTimeout);
    return (
      <ContentBox data-testid="StartTripScreen">
        <PickUpBike
          systemId={systemId}
          kioskId={kioskId}
          navigate={navigate}
          trip={trip}
          expiresAt={expiresAt}
          userVehicleState={trip.userVehicleState}
        />
      </ContentBox>
    );
  }
  let defaultCountryCode = (system && system.phoneCountryCode) || "47";
  if (defaultCountryCode.startsWith("+")) {
    defaultCountryCode = defaultCountryCode.substr(1);
  }
  return (
    <Flex height="100%" alignItems="center">
      <StartTripForm
        systemId={systemId}
        kioskId={kioskId}
        defaultCountryCode={defaultCountryCode}
        webUrl={system && system.webUrl}
        onCompleted={data => {
          if (data && data.startTrip) {
            setTrip(data.startTrip);
          }
        }}
      />
    </Flex>
  );
}
