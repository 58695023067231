// @flow

import * as React from "react";
import invariant from "invariant";
import { Form, type FormikProps } from "formik";
import Keyboard from "../Keyboard";
import { MAX_LENGTHS } from "./constants";

import type { Values, InputType } from "./types";

type State = { focusedInput?: InputType };
type Props = {
  initialFocusedInput: InputType,
  formik: FormikProps<Values>,
  children: (
    State & {
      onInputFocus: (inputName: InputType) => void,
      keyboard: React.Node
    }
  ) => React.Node
};

export default class FormWithKeyboard extends React.Component<Props, State> {
  state = {
    focusedInput: this.props.initialFocusedInput
  };
  onInputFocus = (focusedInput: InputType) => {
    this.setState({ focusedInput });
  };
  render() {
    const { children, formik, initialFocusedInput, ...props } = this.props;
    const { focusedInput } = this.state;

    return (
      <Form {...props}>
        {children({
          focusedInput,
          onInputFocus: this.onInputFocus,
          keyboard: (
            <Keyboard
              onButtonClick={key => {
                invariant(focusedInput, "focusedInput not provided");

                const oldValue = formik.values[focusedInput] || "";
                const value =
                  key === "Backspace" ? oldValue.slice(0, -1) : oldValue + key;

                if (value.length > MAX_LENGTHS[focusedInput]) {
                  return;
                }

                formik.setFieldValue(focusedInput, value);
              }}
            />
          )
        })}
      </Form>
    );
  }
}
