// @flow

import * as React from "react";
import gql from "graphql-tag";
import { Query, type QueryRenderProps } from "react-apollo";

import type {
  bootstrap,
  bootstrapVariables,
  howItWorks,
  howItWorksVariables,
  dockGroupCount
} from "../core.flow";

export const HowItWorksQuery = ({
  children,
  ...props
}: {
  variables: howItWorksVariables,
  children: (
    props: QueryRenderProps<howItWorks, howItWorksVariables>
  ) => React.Node
}) => {
  return (
    <Query
      query={gql`
        query howItWorks($systemId: ID!, $locale: String!) {
          articlePageBySlug(
            systemId: $systemId
            locale: $locale
            slug: "how-it-works"
          ) {
            _id
            _type
            locale
            title
            heading
            intro
            content
          }
        }
      `}
      {...props}
    >
      {children}
    </Query>
  );
};

const BOOTSTRAP_QUERY = gql`
  query bootstrap($systemId: ID!, $kioskId: ID!, $locale: String!) {
    system(systemId: $systemId, locale: $locale) {
      id
      name
      currency
      colourKey
      defaultLanguageCode
      languageCodes
      phoneCountryCode
      webUrl
      season {
        startDate
      }
      inSeason
      workingHours {
        insideOpeningHours
        closedReason
        startDate
        endDate
      }
      coords {
        lat
        lng
      }
    }
    stationClient(kioskId: $kioskId) {
      dockGroup {
        id
        name
        title
        coord {
          lat
          lng
        }
      }
    }
    homeScreen(systemId: $systemId, locale: $locale) {
      heading
      publicServiceAnnouncement
    }
    haveAboutScreen(systemId: $systemId)
  }
`;

export const BootstrapQuery = (props: {
  variables: bootstrapVariables,
  children: (
    props: QueryRenderProps<bootstrap, bootstrapVariables>
  ) => React.Node
}) => {
  return <Query query={BOOTSTRAP_QUERY} {...props} />;
};

export const DockGroupCountQuery = (props: {
  children: (props: QueryRenderProps<dockGroupCount>) => React.Node
}) => {
  return (
    <Query
      query={gql`
        query dockGroupCount($systemId: ID!) {
          dockGroupCount(systemId: $systemId)
        }
      `}
      {...props}
    />
  );
};
