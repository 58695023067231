// @flow

import styled from "styled-components";
import { Button as UIKitButton } from "@urbaninfrastructure/react-ui-kit";

const Button = styled(UIKitButton)`
  &:active,
  &:focus {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default Button;
