// @flow

import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { client, waitOnCache } from "./lib/init-apollo";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./lib/sentry";

const rootEl = document.getElementById("root");

if (!rootEl) {
  throw new Error("#root not defined");
}

waitOnCache.then(() => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    rootEl
  );
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
