// @flow

import * as React from "react";
import { Flex, Box, Button } from "@urbaninfrastructure/react-ui-kit";
import Link from "../Link";
import { I18NBack } from "../i18n";
import LanguageSelector, {
  type Props as LanguageSelectorProps
} from "./LanguageSelector";

type Props = LanguageSelectorProps & {
  showBackButton: boolean
};

const Header = ({
  onLanguageClick,
  availableLocales,
  showBackButton
}: Props) => {
  return (
    <Flex
      py={6}
      px={7}
      alignItems="center"
      justifyContent="center"
      height="10vh"
      width={1}
    >
      {showBackButton && (
        <Box flex={1} textAlign="left">
          <Link to="">
            <Button>
              <I18NBack />
            </Button>
          </Link>
        </Box>
      )}
      <LanguageSelector
        onLanguageClick={onLanguageClick}
        availableLocales={availableLocales}
      />
    </Flex>
  );
};

export default Header;
