// @flow

import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Mutation,
  type MutationFunction,
  type MutationResult
} from "react-apollo";
import { Formik, type FormikProps } from "formik";
import useOnlineStatus from "@rehooks/online-status";
import {
  Flex,
  Box,
  Label,
  Button,
  Text
} from "@urbaninfrastructure/react-ui-kit";
import { KeyboardInput, ContentBox } from "../../components";
import { START_TRIP_MUTATION } from "../../lib/mutations";
import { countryCodes } from "../../lib/data";
import PhoneNumberInputGroup from "../PhoneNumberInputGroup";
import FormWithKeyboard from "./FormWithKeyboard";
import ErrorMessages from "./ErrorMessages";
import { MAX_LENGTHS } from "./constants";

import type {
  startTrip as startTripMutation,
  startTripVariables as startTripMutationVariables
} from "../../core.flow";
import type { Values } from "./types";

type InputProps = {
  systemId: string,
  kioskId: string,
  defaultCountryCode: string,
  webUrl: ?string,
  onCompleted: startTripMutation => void
};

type StartTripMutation = MutationFunction<
  startTripMutation,
  startTripMutationVariables
>;

type StartTripFormProps = {
  systemId: string,
  kioskId: string,
  defaultCountryCode: string,
  webUrl: ?string
} & MutationResult<startTripMutation> & {
    startTrip: StartTripMutation
  };

export const StartTripForm = ({
  defaultCountryCode,
  systemId,
  kioskId,
  error,
  loading,
  data,
  startTrip,
  webUrl
}: StartTripFormProps) => {
  const isOnline = useOnlineStatus();

  const initialValues: Values = {
    countryCode: defaultCountryCode,
    phoneNumber: "",
    code: ""
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: Values) => {
        const errors = {};

        if (!values.phoneNumber) {
          errors.phoneNumber = "PHONE_NUMBER_REQUIRED";
        }
        if (
          !values.countryCode ||
          !Object.values(countryCodes).includes(values.countryCode)
        ) {
          errors.countryCode = "COUNTRY_CODE_INVALID";
        }

        if (!values.code || values.code.length !== MAX_LENGTHS.code) {
          errors.code = "CODE_INVALID_LENGTH";
        }

        return errors;
      }}
      onSubmit={async (values: Values) => {
        startTrip({
          variables: {
            systemId,
            kioskId,
            phoneNumber: "+" + values.countryCode + values.phoneNumber,
            code: values.code
          }
        });
      }}
      render={(formik: FormikProps<Values>) => {
        const { values, isValid, errors, submitCount, setFieldValue } = formik;
        return (
          <Box position="relative" width={1}>
            <ErrorMessages
              offline={!isOnline}
              error={error}
              clientErrors={errors}
              isValid={isValid}
              submitCount={submitCount}
              webUrl={webUrl}
            />
            <Box mb="lg">
              <FormWithKeyboard
                formik={formik}
                initialFocusedInput="phoneNumber"
              >
                {({ onInputFocus, focusedInput, keyboard }) => {
                  return (
                    <ContentBox data-testid="StartTrip">
                      <Flex mb={4} textAlign="left">
                        <Box mr={4} width={3 / 4}>
                          <Label htmlFor="phoneNumber">
                            <FormattedMessage
                              id="StartTripForm__PhoneNumber"
                              defaultMessage="Phone number"
                            />
                          </Label>
                          <PhoneNumberInputGroup
                            countryCode={values.countryCode}
                            phoneNumber={values.phoneNumber}
                            focusedInput={focusedInput}
                            onInputClick={inputName => {
                              if (inputName === "countryCode") {
                                setFieldValue("countryCode", "");
                              }
                              onInputFocus(inputName);
                            }}
                          />
                        </Box>
                        <Box width={1 / 4}>
                          <Label htmlFor="code">
                            <FormattedMessage
                              id="StartTripForm__PinCode"
                              defaultMessage="Pin code"
                            />
                          </Label>
                          <KeyboardInput
                            id="code"
                            name="code"
                            type="password"
                            data-testid="StartTripForm__CodeInput"
                            onClick={() => onInputFocus("code")}
                            focused={focusedInput === "code"}
                          >
                            {values.code}
                          </KeyboardInput>
                        </Box>
                      </Flex>
                      {keyboard}
                      <Button
                        type="submit"
                        mt={4}
                        jumbo
                        shape="cool"
                        data-testid="StartTripForm__SubmitButton"
                        disabled={!isOnline}
                      >
                        {loading ? (
                          <FormattedMessage
                            id="StartTripForm__UnlockButton__Unlocking"
                            defaultMessage="Unlocking..."
                          />
                        ) : (
                          <FormattedMessage
                            id="StartTripForm__UnlockButton__Unlock"
                            defaultMessage="Unlock"
                          />
                        )}
                      </Button>
                    </ContentBox>
                  );
                }}
              </FormWithKeyboard>
            </Box>
            <Text typoStyle="sm" mx="md">
              <FormattedMessage
                id="StartTripForm__PinCodeHelp"
                defaultMessage="Don't have a pin-code yet? Log into you profile at {webUrl} and set a personal code under the page «PIN code»."
                values={{ webUrl }}
              />
            </Text>
          </Box>
        );
      }}
    />
  );
};

const StartTripFormWithMutation = ({ onCompleted, ...props }: InputProps) => {
  return (
    <Mutation mutation={START_TRIP_MUTATION} onCompleted={onCompleted}>
      {(
        startTrip: StartTripMutation,
        mutationResultProps: MutationResult<startTripMutation>
      ) => {
        return (
          <StartTripForm
            startTrip={startTrip}
            {...props}
            {...mutationResultProps}
          />
        );
      }}
    </Mutation>
  );
};

export default StartTripFormWithMutation;
