// @flow

import gql from "graphql-tag";

export const START_TRIP_MUTATION = gql`
  mutation startTrip(
    $code: String!
    $phoneNumber: String!
    $systemId: ID!
    $kioskId: ID!
  ) {
    startTrip(
      code: $code
      phoneNumber: $phoneNumber
      systemId: $systemId
      kioskId: $kioskId
    ) {
      id
      vehicle {
        id
        name
      }
      startDock {
        id
        number
      }
      userVehicleState
    }
  }
`;
