// @flow

import * as React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import mapboxgl from "mapbox-gl";
import { useQuery } from "@apollo/react-hooks";
import { Box, Flex, Text, Alert } from "../../components";
import { useStyledTheme } from "../../lib/hooks";
import { DOCK_GROUP_QUERY } from "./graphql";
import MapboxMarker from "./MapboxMarker";

// $FlowFixMe
import "mapbox-gl/dist/mapbox-gl.css";

import type {
  dockGroups,
  dockGroupsVariables,
  bootstrap_system,
  bootstrap_stationClient_dockGroup
} from "../../core.flow";

type Props = {
  system: ?bootstrap_system,
  dockGroup: ?bootstrap_stationClient_dockGroup,
  height: string | number
};

const MapContainer = styled(Box)`
  position: absolute !important;
  a {
    pointer-events: none;
  }
  .mapbox-improve-map {
    display: none;
  }
`;

const DOT_FONT_SIZE = 12;
const DOT_RADIUS = 12;
const DATA_SOURCE_ID = "dockGroups";

function dockGroupsToFeatureCollection({ dockGroups, currentDockGroupId }) {
  const features = dockGroups.reduce((accum, dockGroup, i) => {
    if (dockGroup.id !== currentDockGroupId) {
      accum.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [dockGroup.coord.lng, dockGroup.coord.lat]
        },
        properties: {
          id: dockGroup.id,
          availableVehicles: dockGroup.availabilityInfo.availableVehicles
        }
      });
    }
    return accum;
  }, []);
  return {
    type: "FeatureCollection",
    features
  };
}

function StationMap({ systemId, bounds, dockGroup }) {
  const map = React.useRef();
  const mapContainerRef = React.useRef();
  const theme = useStyledTheme();

  const { data, error } = useQuery<dockGroups, dockGroupsVariables>(
    DOCK_GROUP_QUERY,
    {
      variables: {
        systemId
      },
      pollInterval: 1000 * 10
    }
  );

  const currentDockGroupId = dockGroup.id;

  if (data && map.current && map.current.loaded()) {
    const source = map.current.getSource(DATA_SOURCE_ID);
    if (source) {
      source.setData(
        dockGroupsToFeatureCollection({
          dockGroups: data.dockGroups,
          currentDockGroupId
        })
      );
    }
  }

  React.useLayoutEffect(() => {
    if (bounds) {
      if (map.current && map.current.loaded()) {
        return;
      }
      map.current = new mapboxgl.Map({
        accessToken:
          "pk.eyJ1IjoidXJiYW5zaGFyaW5nIiwiYSI6ImNqemI2dmRuYzAybmwzY21odHI0Zmp4MHUifQ.Zlc6OHXEhMhdigu1QtWJOw",
        style: "mapbox://styles/mapbox/streets-v9",
        container: mapContainerRef.current,
        bounds,
        maxBounds: bounds,
        center: dockGroup.coord
      });

      const current = map.current;

      if (!current) {
        return;
      }

      const nav = new mapboxgl.NavigationControl({ showCompass: false });
      current.addControl(nav, "top-left");

      // disable map rotation using right click + drag
      current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      current.touchZoomRotate.disableRotation();

      current.on("load", () => {
        const dockGroups = data && data.dockGroups;
        if (dockGroups) {
          if (!current) {
            return;
          }
          const source = {
            type: "geojson",
            data: dockGroupsToFeatureCollection({
              dockGroups,
              currentDockGroupId
            })
          };

          current.addSource(DATA_SOURCE_ID, source);

          if (!current.getLayer("dockGroups-dots")) {
            const dots = {
              id: "dockGroups-dots",
              source: DATA_SOURCE_ID,
              type: "circle",

              paint: {
                "circle-stroke-width": 2,
                "circle-stroke-color": theme.colors.primary,
                "circle-color": [
                  "match",
                  ["get", "availableVehicles"],
                  0,
                  "#ffffff",
                  /* other */ theme.colors.primary
                ],
                "circle-radius": DOT_RADIUS
              }
            };
            current.addLayer(dots);
          }

          const currentDockGroup = dockGroups.find(
            dockGroup => dockGroup.id === currentDockGroupId
          );
          if (currentDockGroup) {
            new mapboxgl.Marker({ color: theme.colors.primary })
              .setLngLat(currentDockGroup.coord)
              .addTo(map.current);
          }

          if (!current.getLayer("dockGroups-text")) {
            const text = {
              id: "dockGroups-text",
              source: DATA_SOURCE_ID,
              type: "symbol",
              layout: {
                "text-field": "{availableVehicles}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": DOT_FONT_SIZE
              },
              paint: {
                "text-color": [
                  "match",
                  ["get", "availableVehicles"],
                  0,
                  theme.colors.text,
                  /* other */ "#fff"
                ]
              }
            };
            current.addLayer(text);
          }
        }
      });
    }
  }, [bounds, data, currentDockGroupId, theme, dockGroup.coord]);

  if (error) {
    return (
      <Alert>
        <FormattedMessage
          id="StationMap.error"
          defaultMessage="Could not load map"
        />
      </Alert>
    );
  }

  return (
    <MapContainer top={0} right={0} bottom={0} left={0} ref={mapContainerRef} />
  );
}

function Dot(props) {
  return (
    <Flex
      fontSize={DOT_FONT_SIZE}
      borderRadius="50%"
      size={DOT_RADIUS * 2}
      bg="primary"
      color="white"
      border="2px solid"
      borderColor="primary"
      alignItems="center"
      justifyContent="center"
      lineHeight="0"
      {...props}
    ></Flex>
  );
}

export default function StationMapPage({ height, system, dockGroup }: Props) {
  const [bounds, setBounds] = React.useState();
  const theme = useStyledTheme();

  React.useEffect(() => {
    if (!system || !system.coords) {
      return;
    }
    if (system.coords.length < 2) {
      return;
    }
    const bounds = system.coords.reduce((bounds, coord) => {
      if (coord) {
        // $FlowFixMe, this is mapbox LngLatBounds
        return bounds.extend([coord.lng, coord.lat]);
      }
      return bounds;
    }, new mapboxgl.LngLatBounds(system.coords[0], system.coords[0]));
    setBounds(bounds);
  }, [system]);

  if (!system || !dockGroup) {
    return null;
  }

  return (
    <Flex flexDirection="column" height={height}>
      <Box position="relative" flex="1 1 auto" height="100%" width={1}>
        <StationMap
          systemId={system.id}
          bounds={bounds}
          dockGroup={dockGroup}
        />
      </Box>
      <Box bg="white" color="text" p={5}>
        <Flex alignItems="center" mb={2}>
          <Box mr={3}>
            <MapboxMarker color={theme.colors.primary} />
          </Box>
          <Text lineHeight={1}>
            <FormattedMessage
              id="StationMap.legend.currentStation"
              defaultMessage="This station"
            />
          </Text>
        </Flex>
        <Flex alignItems="center" mb={2}>
          <Dot mr={3}>25</Dot>
          <Text lineHeight={1}>
            <FormattedMessage
              id="StationMap.legend.availableVehicles"
              defaultMessage="Stations with available bikes"
            />
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Dot mr={3} bg="white" color="text">
            0
          </Dot>
          <Text lineHeight={1}>
            <FormattedMessage
              id="StationMap.legend.emptyStation"
              defaultMessage="Stations without available bikes"
            />
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}
