// @flow

import * as React from "react";
import { Alert as UIKitAlert } from "@urbaninfrastructure/react-ui-kit";

const Alert = (props: Object) => {
  return <UIKitAlert mb={4} {...props} />;
};

export default Alert;
