// @flow

import * as React from "react";
import { FormattedMessage } from "react-intl";

import type { bootstrap_system_workingHours } from "../../core.flow";

type Props = {
  workingHours: bootstrap_system_workingHours,
  inSeason: boolean
};

export default function Closed({ workingHours, inSeason }: Props) {
  let reason;
  if (!inSeason) {
    reason = (
      <FormattedMessage
        id="components.Closed.notInSeason"
        defaultMessage="We're closed for the season."
      />
    );
  } else {
    if (workingHours.insideOpeningHours) {
      return null;
    }

    reason = workingHours.closedReason;

    if (!reason) {
      if (!workingHours.startDate || !workingHours.endDate) {
        reason = (
          <FormattedMessage
            id="components.Closed.reason.unknown"
            defaultMessage="We're currently closed."
          />
        );
      } else {
        reason = (
          <FormattedMessage
            id="components.Closed.outsideOpeningHours"
            defaultMessage="We're closed for the day."
          />
        );
      }
    }
  }

  return reason;
}
