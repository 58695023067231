// @flow

import * as React from "react";
import { createGlobalStyle } from "styled-components";
import { BaseStyles as UIKitBaseStyles } from "@urbaninfrastructure/react-ui-kit";
import getTheme from "../lib/get-theme";

type Props = {
  colourKey: ?string,
  children: React.Node
};

const GlobalStyle = createGlobalStyle`
  *:focus {
    outline: none;
  }
  html {
    overflow-y: visible;
    touch-action: none;
  }
  body {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default function BaseStyles({ colourKey, children }: Props) {
  const theme = getTheme(colourKey);
  return (
    <UIKitBaseStyles
      colorKey={colourKey}
      font="urban-grotesk"
      fontPath="/fonts/urban-grotesk/"
      theme={theme}
    >
      <>
        <GlobalStyle />
        {children}
      </>
    </UIKitBaseStyles>
  );
}
