// @flow

import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import scrollIntoView from "scroll-into-view-if-needed";
import { Box } from "@urbaninfrastructure/react-ui-kit";
import H2 from "../H2";
import { useStyledTheme } from "../../lib/hooks";

type Props = {
  items: Array<{
    id: string,
    question: React.Node,
    answer: React.Node
  }>
};

function AccordionItem({ isExpanded, children }) {
  const ref = React.useRef(null);

  if (!isExpanded) {
    return null;
  }

  return (
    <motion.div
      key="content"
      ref={ref}
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
      }}
      transition={{
        duration: 0.1
      }}
      onAnimationComplete={() => {
        if (ref.current && isExpanded) {
          // scrollIntoViewIfNeeded is actually supported by Chrome, but since it's not an official standard
          // it might be removed in the future, breaking things. Hence, the ponyfill scroll-into-view-if-needed
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
          scrollIntoView(ref.current, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth"
          });
        }
      }}
    >
      {children}
    </motion.div>
  );
}

export default function Accordion({ items }: Props) {
  const [expandedId, setExpandedId] = React.useState(null);
  const theme = useStyledTheme();
  return items.map<React.Node>(item => {
    const isExpanded = expandedId === item.id;
    return (
      <Box
        key={item.id}
        onClick={() => {
          setExpandedId(!isExpanded ? item.id : null);
        }}
        mb={5}
        overflow="hidden"
        boxShadow="heavy"
        bg="white"
        color="text"
        borderRadius="lg"
        p={5}
      >
        <motion.div
          initial={false}
          animate={{
            marginBottom: isExpanded ? theme.space[3] : 0
          }}
          onClick={() => setExpandedId(isExpanded ? null : item.id)}
        >
          <H2
            fontSize={4}
            mb={0}
            onClick={() => setExpandedId(isExpanded ? null : item.id)}
          >
            {item.question}
          </H2>
        </motion.div>
        <AnimatePresence initial={false}>
          <AccordionItem key="content" isExpanded={isExpanded}>
            {item.answer}
          </AccordionItem>
        </AnimatePresence>
      </Box>
    );
  });
}
