// @flow

import * as React from "react";
// https://thenounproject.com/search/?q=erase&i=194048#
export default (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 30 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.5,2.509V2.5h-12c-0.55,0-1.299,0.336-1.665,0.748l-6.671,7.505c-0.366,0.411-0.366,1.083,0,1.496l6.671,7.505  C9.201,20.164,9.95,20.5,10.5,20.5h12c1.104,0,2-0.896,2-2V4.509C24.5,3.404,23.604,2.509,22.5,2.509z M20.384,14.616  c0.488,0.489,0.488,1.28,0,1.768c-0.243,0.244-0.563,0.366-0.884,0.366s-0.641-0.122-0.884-0.366L15.5,13.268l-3.116,3.116  C12.14,16.628,11.82,16.75,11.5,16.75s-0.64-0.122-0.884-0.366c-0.488-0.489-0.488-1.28,0-1.768l3.116-3.116l-3.116-3.116  c-0.488-0.489-0.489-1.28,0-1.768c0.489-0.488,1.28-0.488,1.768,0L15.5,9.732l3.116-3.116c0.489-0.488,1.28-0.489,1.768,0  c0.488,0.489,0.488,1.28,0,1.768L17.268,11.5L20.384,14.616z"
    />
  </svg>
);
