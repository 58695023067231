// @flow

import * as React from "react";
import styled from "styled-components";
import { Flex } from "@urbaninfrastructure/react-ui-kit";
import KeyboardInput from "../KeyboardInput";

type InputType = "phoneNumber" | "countryCode" | "code";

type Props = {
  countryCode: string,
  phoneNumber: string,
  focusedInput?: InputType,
  onInputClick: (inputName: InputType) => void
};

type State = {};

const CountryCodeInput = styled(KeyboardInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  &:before {
    content: "+";
  }
`;

const PhoneNumberInput = styled(KeyboardInput)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 1;
`;

export default class PhoneNumberInputGroup extends React.Component<
  Props,
  State
> {
  static defaultProps = {
    onInputClick: () => {}
  };
  render() {
    const {
      countryCode,
      phoneNumber,
      focusedInput,
      onInputClick,
      ...props
    } = this.props;
    return (
      <Flex>
        <CountryCodeInput
          value={countryCode}
          focused={focusedInput === "countryCode"}
          color="primary"
          bg="white"
          minWidth="4em"
          textAlign="center"
          data-testid="PhoneNumberInputGroup__CountryCode"
          onClick={() => onInputClick("countryCode")}
        >
          {countryCode}
        </CountryCodeInput>
        <PhoneNumberInput
          value={phoneNumber}
          focused={focusedInput === "phoneNumber"}
          data-testid="PhoneNumberInputGroup__PhoneNumber"
          onClick={() => onInputClick("phoneNumber")}
          {...props}
        />
      </Flex>
    );
  }
}
