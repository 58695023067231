// @flow

import * as React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { FormattedMessage, FormattedDate } from "react-intl";
import {
  Heading as H1,
  H3,
  Text,
  Table,
  Tr,
  List,
  Box
} from "@urbaninfrastructure/react-ui-kit";
import H2 from "./H2";
import SanityBlockContent from "@sanity/block-content-to-react";
import getTheme from "../lib/get-theme";
import { BootstrapQuery, DockGroupCountQuery } from "../lib/queries";

type LinkSerializerProps = {
  mark: { href: string, _type: string, _refType: string, slug?: string },
  children: React.Node
};

const imageOptions = { w: 1200, h: 600, fit: "max" };

type Props = {
  systemId: string,
  locale: string,
  kioskId: string,
  blocks?: any,
  serializers?: {
    marks: any,
    types: any,
    list: any
  }
};

function Noop(props: LinkSerializerProps) {
  return props.children;
}

const Base = styled(Box)`
  ul,
  ol {
    margin-top: ${props => props.theme.space[6]}px;
    margin-bottom: ${props => props.theme.space[6]}px;
    text-align: left;
  }
  figure {
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }
  p + p {
    margin-bottom: ${props => props.theme.space[4]}px;
  }
`;

Base.defaultProps = {
  theme: getTheme()
};

const BlockRenderer = (props: {
  node: { style: string },
  children: React.Node
}) => {
  const style = props.node.style || "normal";

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, "");
    if (level === "1") {
      return <H1 data-testid="BlockRenderer__Heading1">{props.children}</H1>;
    }
    if (level === "2") {
      return (
        <H2 data-testid="BlockRenderer__Heading2" mt="md">
          {props.children}
        </H2>
      );
    }
    if (level === "3") {
      return <H3 mt="sm">{props.children}</H3>;
    }
  }

  switch (style) {
    case "blockquote":
      return <blockquote>{props.children}</blockquote>;
    case "lead":
      return (
        <Text as="p" lead>
          {props.children}
        </Text>
      );
    case "small":
      return (
        <Text as="p" small>
          {props.children}
        </Text>
      );
    default:
      return <Text as="p">{props.children}</Text>;
  }
};

function MarkdownTable(props) {
  return <Table variant="simple" {...props} />;
}

function MarkdownTableCell({ isHeader, ...props }: { isHeader: boolean }) {
  // getting a `Error: Uncaught [TypeError: Cannot read property '0' of undefined]` error if returning Table.th/Table.td when testing with react-testing-library
  if (process.env.NODE_ENV === "test") {
    if (isHeader) {
      return <th {...props} />;
    }
    return <td {...props} />;
  }
  if (isHeader) {
    return <Table.th textAlign="left" {...props} />;
  }
  return <Table.td {...props} />;
}

function MarkdownRenderer(props: { node?: { content: ?string } }) {
  if (!props.node || !props.node.content) {
    return null;
  }
  return (
    <ReactMarkdown
      source={props.node.content}
      renderers={{
        table: MarkdownTable,
        tableRow: function TableRow(props) {
          return <Tr {...props} />;
        },
        tableCell: MarkdownTableCell
      }}
    />
  );
}

const createDockGroupCountRenderer = ({ systemId }) => {
  return function DockGroupCountRenderer({
    node
  }: {
    node: { formatted: boolean }
  }) {
    return (
      <DockGroupCountQuery variables={{ systemId }}>
        {({ data, loading }) => {
          const count = loading ? "..." : (data && data.dockGroupCount) || 0;
          if (node.formatted) {
            return (
              <FormattedMessage
                id="BlockContent.DockGroupCountRenderer.stations"
                defaultMessage="{count} {count, plural, one {station} other {stations}}"
                values={{ count }}
              />
            );
          }
          return count;
        }}
      </DockGroupCountQuery>
    );
  };
};

const createSystemSeasonStartDateRenderer = ({ data, loading }) => {
  return function SystemSeasonStartDateRenderer() {
    if (loading) {
      return "…";
    }
    if (!data || !data.system || !data.system.season) {
      return null;
    }
    const { startDate } = data.system.season;

    if (!startDate) {
      return null;
    }

    return <FormattedDate value={startDate} month="long" day="numeric" />;
  };
};

const BlockContent = ({ systemId, locale, kioskId, ...props }: Props) => {
  let marks = {
    page: Noop,
    link: Noop
  };

  return (
    <BootstrapQuery variables={{ systemId, locale, kioskId }}>
      {({ data, loading }) => {
        const types = {
          markdown: MarkdownRenderer,
          image: () => null,
          dockGroupCount: createDockGroupCountRenderer({ systemId }),
          systemSeasonStartDate: createSystemSeasonStartDateRenderer({
            data,
            loading
          }),
          block: BlockRenderer,
          ctaLink: () => null
        };

        const serializers = {
          marks,
          types,
          list: function ListSerializer({
            type,
            children
          }: {
            type: "bullet" | "number",
            children: React.Node
          }) {
            const props = {};
            if (type === "bullet") {
              props.listStyle = "disc";
              props.as = "ul";
            } else if (type === "number") {
              props.listStyle = "decimal";
              props.as = "ol";
            }
            return (
              <List {...props} pl={{ xs: 4, lg: 5, xl: 0 }} my="md">
                {children}
              </List>
            );
          }
        };

        return (
          <Base data-testid="BlockContentBase">
            <SanityBlockContent
              serializers={serializers}
              imageOptions={imageOptions}
              {...props}
            />
          </Base>
        );
      }}
    </BootstrapQuery>
  );
};

export default BlockContent;
