// @flow

import * as React from "react";
import styled, { keyframes } from "styled-components";
import { Text, Dot } from "@urbaninfrastructure/react-ui-kit";

type Props = {
  children?: React.Node
};

const pulseAnimation = keyframes`
  0%   { opacity: 1 }
  50%  { opacity: 0 }
  100% { opacity: 1 }
`;

const PulsingDot = styled(Dot)`
  animation: ${pulseAnimation} 1s infinite linear;
`;

export default function Loading({ children, ...props }: Props) {
  return (
    <Text fontSize={1} mute {...props}>
      <PulsingDot bg="white">{children}</PulsingDot>
    </Text>
  );
}

Loading.defaultProps = {
  size: 1
};
