// @flow

const hostname = window && window.location && window.location.hostname;

const hosts = {
  staging: "station-client.staging.urbansharing.tech",
  production: "station-client.urbansharing.com"
};

let backendHost = process.env.REACT_APP_BACKEND_HOST || "http://localhost:4000";

if (hostname === hosts.staging) {
  backendHost = "https://core.staging.urbansharing.tech";
} else if (hostname === hosts.production) {
  backendHost = "https://core.urbansharing.com";
}

let name;
const staging = hostname === hosts.staging;
const production = hostname === hosts.production;

switch (process.env.NODE_ENV) {
  case "production": {
    if (staging) {
      name = "staging";
    }
    if (production) {
      name = "production";
    }
    break;
  }
  case "test": {
    name = "test";
    break;
  }
  case "development":
  default: {
    name = "development";
  }
}

const env = {
  name,
  development: name === "development",
  test: name === "test",
  staging,
  production,
  backendHost,
  hosts
};

export default env;
