// @flow

import * as React from "react";

// TODO SHARE THIS COMPONENT WITH PUBLIC-WEB

export type State = {
  total: number,
  seconds: string,
  minutes: string,
  completed: boolean
};

type Props = {
  date: Date | string | number,
  intervalDelay: number,
  precision: number,
  render: State => React.Node,
  now?: () => number,
  onComplete?: () => void
};

const pad = n => (n < 10 ? `0${n}` : `${n}`);

const getTimeDifference = (
  date: Date | string | number,
  now: () => number = Date.now,
  precision: number = 0
) => {
  const startDate = typeof date === "string" ? new Date(date) : date;
  const total =
    parseInt(
      (Math.max(0, startDate - now()) / 1000).toFixed(
        Math.max(0, Math.min(20, precision))
      ),
      10
    ) * 1000;

  const seconds = total / 1000;

  return {
    total,
    minutes: pad(Math.floor((seconds / 60) % 60)),
    seconds: pad(Math.floor(seconds % 60)),
    completed: total <= 0
  };
};

export default class Countdown extends React.Component<Props, State> {
  static defaultProps = {
    intervalDelay: 1000,
    precision: 0
  };
  state = getTimeDifference(
    this.props.date,
    this.props.now,
    this.props.precision
  );
  interval: IntervalID;
  componentDidMount() {
    this.interval = setInterval(this.tick, this.props.intervalDelay);
  }
  componentWillUnmount() {
    this.clearInterval();
  }
  clearInterval() {
    clearInterval(this.interval);
    delete this.interval;
  }
  tick = () => {
    const { date, now, precision, onComplete } = this.props;
    const delta = getTimeDifference(date, now, precision);
    if (delta.completed && onComplete) {
      onComplete();
      this.clearInterval();
    }
    this.setState(delta);
  };
  render() {
    return this.props.render(this.state);
  }
}
