// @flow

import * as React from "react";
import styled from "styled-components";
import { Flex, Button, ActivateForm, Logo } from "../components";

type Props = {
  kioskId: string,
  onActivated: () => void
};

const HiddenButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1em;
`;

export default function Activate({ kioskId, onActivated }: Props) {
  const [showForm, setShowForm] = React.useState(false);
  if (!showForm) {
    return (
      <Flex data-testid="Activate" alignItems="center" height="100%">
        <Logo width="50vh" height="50vw" />
        <HiddenButton
          data-testid="Activate__HiddenButton"
          variant="reset"
          color="primary"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Start provisioning
        </HiddenButton>
      </Flex>
    );
  }
  return (
    <ActivateForm
      kioskId={kioskId}
      handleCancelClick={() => {
        setShowForm(false);
      }}
      onSubmitSuccess={onActivated}
    />
  );
}
