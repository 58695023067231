// @flow

import * as React from "react";
import { Link as ReachLink } from "@reach/router";

import type { RouteType } from "../types";

type Props = {
  to: RouteType,
  children: React.Node
};

function Link(props: Props) {
  return <ReachLink {...props} />;
}

export default Link;
