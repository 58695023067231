// @flow

import * as React from "react";
import * as Sentry from "@sentry/browser";
import { FormattedMessage } from "react-intl";
import { Text, Flex, Button, Box } from "@urbaninfrastructure/react-ui-kit";
import { Warning as WarningIcon } from "@urbaninfrastructure/react-icons";
import BaseStyles from "./BaseStyles";

export function GenericErrorComponent({
  colourKey,
  skipProviders
}: {
  colourKey: ?string,
  skipProviders?: boolean
}) {
  const component = (
    <Flex
      bg="primary"
      color="white"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box mb="sm">
        <WarningIcon size={150} />
      </Box>
      <Text mb="md" typoStyle="lg">
        <FormattedMessage
          id="ErrorBoundary.errorMessage"
          defaultMessage="Something went wrong"
        />
      </Text>
      <Button
        onClick={() => (window.location = window.location.pathname)}
        jumbo
      >
        <FormattedMessage id="ErrorBoundary.reload" defaultMessage="Reload" />
      </Button>
    </Flex>
  );
  if (skipProviders) {
    return component;
  }
  return <BaseStyles colourKey={colourKey}>{component}</BaseStyles>;
}

export default class ErrorBoundary extends React.Component<
  {
    children: React.Node,
    kioskId: ?string,
    systemId: ?string,
    colourKey: ?string,
    skipProviders?: boolean
  },
  { error: ?Error, eventId: ?string }
> {
  state = {
    error: null,
    eventId: null
  };
  static defaultProps = {
    locale: "en"
  };
  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string
    }
  ) {
    const { kioskId, systemId } = this.props;
    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtra("kioskId", kioskId);
      scope.setExtra("systemId", systemId);
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { children, colourKey, skipProviders } = this.props;
    if (this.state.error) {
      return (
        <GenericErrorComponent
          colourKey={colourKey}
          skipProviders={skipProviders}
        />
      );
    }
    return children;
  }
}
