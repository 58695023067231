// @flow

import * as React from "react";
import { H2 as UIKitH2 } from "@urbaninfrastructure/react-ui-kit";

type Props = {};

export default function H2(props: Props) {
  return <UIKitH2 fontSize={4} {...props} />;
}
