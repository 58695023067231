// @flow

import * as React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";
import { Box } from "@urbaninfrastructure/react-ui-kit";
import Notifications from "../Notifications";
import { MAX_LENGTHS } from "./constants";

import type { ApolloError } from "react-apollo";

const Wrapper = styled(Box)`
  position: absolute;
  bottom: 100%;
`;

Wrapper.defaultProps = {
  mb: 6,
  width: 1
};

const graphQLErrorMessages = defineMessages({
  ERR_GENERIC: {
    id: "graphQLErrors.ERR_GENERIC",
    defaultMessage: "Something went wrong. Please try again."
  },
  ERR_NETWORK_ERROR: {
    id: "graphQLErrors.ERR_NETWORK_ERROR",
    defaultMessage: "Ouch, could not contact the other end. Please try again."
  },
  ERR_INVALID_PHONE_NUMBER: {
    id: "graphQLErrors.ERR_INVALID_PHONE_NUMBER",
    defaultMessage: "Invalid phone number entered."
  },
  ERR_UNKNOWN_PHONE_NUMBER: {
    id: "graphQLErrors.ERR_UNKNOWN_PHONE_NUMBER",
    defaultMessage: "The number does not exist. Check spelling and try again."
  },
  ERR_INVALID_USER_PIN_CODE_OR_PHONE_NUMBER: {
    id: "graphQLErrors.ERR_INVALID_USER_PIN_CODE_OR_PHONE_NUMBER",
    defaultMessage:
      "Wrong number or code entered. Check spelling and try again."
  },
  ERR_USER_NO_SUBSCRIPTION: {
    id: "graphQLErrors.ERR_USER_NO_SUBSCRIPTION",
    defaultMessage:
      "We couldn't find any subscription for your user. You can buy one at {webUrl}"
  },
  ERR_INVALID_STATION_CLIENT_CODE: {
    id: "graphQLErrors.ERR_INVALID_STATION_CLIENT_CODE",
    defaultMessage:
      "Invalid code entered. You can change your code in your profile on {webUrl}"
  },
  ERR_NO_VEHICLES_AVAILABLE: {
    id: "graphQLErrors.ERR_NO_VEHICLES_AVAILABLE",
    defaultMessage: "There are no available vehicles for rental right now"
  }
});

const clientErrorMessages = defineMessages({
  OFFLINE: {
    id: "components.StartTripForm.clientErrors.OFFLINE",
    defaultMessage: "We're currently offline. Please try again later."
  },
  PHONE_NUMBER_REQUIRED: {
    id: "components.StartTripForm.clientErrors.PHONE_NUMBER_REQUIRED",
    defaultMessage: "Enter a phone number"
  },
  CODE_INVALID_LENGTH: {
    id: "components.StartTripForm.clientErrors.CODE_INVALID_LENGTH",
    defaultMessage: "Enter a {codeLength} digit code"
  },
  CODE_REQUIRED: {
    id: "components.StartTripForm.clientErrors.CODE_REQUIRED",
    defaultMessage: "Enter a {codeLength} digit code"
  },
  COUNTRY_CODE_INVALID: {
    id: "components.StartTripForm.clientErrors.COUNTRY_CODE_INVALID",
    defaultMessage: "Enter a valid country code"
  }
});

type Props = {
  offline: boolean,
  isValid: boolean,
  submitCount: number,
  clientErrors?: { [any]: string },
  error?: ApolloError,
  webUrl: ?string
};

const ErrorMessages = ({
  offline,
  isValid,
  submitCount,
  clientErrors,
  error,
  webUrl,
  ...props
}: Props) => {
  let messages = [];

  if (error) {
    if (error.graphQLErrors && error.graphQLErrors.length) {
      messages = error.graphQLErrors.reduce(
        (accum, error: Core$GraphQLError) => {
          if (!error) {
            return messages;
          }
          const { code } = error;
          const message =
            graphQLErrorMessages[code] || graphQLErrorMessages.ERR_GENERIC;
          accum.push(
            <FormattedMessage {...message} values={{ webUrl }}>
              {(txt: string) => <span data-testid={message.id}>{txt}</span>}
            </FormattedMessage>
          );
          return accum;
        },
        messages
      );
    } else if (error.networkError) {
      const message = graphQLErrorMessages.ERR_NETWORK_ERROR;
      messages.push(
        <FormattedMessage {...message}>
          {(txt: string) => <span data-testid={message.id}>{txt}</span>}
        </FormattedMessage>
      );
    } else {
      const message = graphQLErrorMessages.ERR_GENERIC;
      messages.push(
        <FormattedMessage {...message}>
          {(txt: string) => <span data-testid={message.id}>{txt}</span>}
        </FormattedMessage>
      );
    }
  }

  if (offline) {
    const message = clientErrorMessages.OFFLINE;
    messages.push(
      <FormattedMessage {...message}>
        {(txt: string) => <span data-testid={message.id}>{txt}</span>}
      </FormattedMessage>
    );
  }

  if (
    clientErrors &&
    !isValid &&
    submitCount > 0 &&
    Object.keys(clientErrors).length
  ) {
    messages = Object.keys(clientErrors).reduce((accum, key) => {
      const error = clientErrors[key];
      const message = clientErrorMessages[error];
      if (message) {
        accum.push(
          <FormattedMessage
            {...message}
            values={{ codeLength: MAX_LENGTHS.code }}
          />
        );
      }
      return accum;
    }, messages);
  }

  return (
    <Wrapper>
      <Notifications
        notifications={messages.map(message => ({ message, type: "error" }))}
      />
    </Wrapper>
  );
};

ErrorMessages.defaultProps = {
  clientErrors: []
};

export default ErrorMessages;
