// @flow

import * as React from "react";
import * as Sentry from "@sentry/browser";
import { FormattedMessage } from "react-intl";
import QRCode from "qrcode";
import { Mobile } from "@urbaninfrastructure/react-icons";
import { Flex, Box, Text, H1 } from "../../components";
import { useStyledTheme } from "../../lib/hooks";

import type { bootstrap_system } from "../../core.flow";

type Props = {
  system: ?bootstrap_system
};

const QR_WIDTH = 300;

function QRApp({ value }: { value: string }) {
  const canvasRef = React.useRef();
  const theme = useStyledTheme();
  React.useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      value,
      {
        width: QR_WIDTH,
        margin: 0,
        color: { dark: theme.colors.white, light: theme.colors.primary }
      },
      function(error) {
        if (error) {
          Sentry.captureException(error);
        }
      }
    );
  }, [theme.colors.white, theme.colors.primary, value]);
  return <canvas data-testid="QRApp" ref={canvasRef} />;
}

export default function AppScreen({ system }: Props) {
  if (!system || !system.webUrl) {
    return null;
  }
  const { webUrl, name } = system;
  return (
    <Flex
      data-testid="AppScreen"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb="md">
        <Mobile size={180} />
      </Box>
      <H1>
        <FormattedMessage
          id="AppScreen.heading"
          defaultMessage="Get started on your phone"
        />
      </H1>
      <Text as="p" fontSize={3} mb="md" mx="md">
        <FormattedMessage
          id="AppScreen.description"
          defaultMessage="Download {systemName} in the App Store or Google Play by either scanning the QR code below or visit {webUrl}/app"
          values={{ webUrl, systemName: name }}
        />
      </Text>
      <Box mx="auto" size={QR_WIDTH}>
        <QRApp value={`${webUrl}/app`} />
      </Box>
    </Flex>
  );
}
