// @flow

import * as React from "react";
import gql from "graphql-tag";
import {
  Mutation,
  type MutationFunction,
  type MutationResult,
  type ApolloError
} from "react-apollo";
import { Formik, Form, type FormikProps } from "formik";
import {
  Label,
  Alert,
  Box,
  Button,
  ButtonGroup,
  Text
} from "@urbaninfrastructure/react-ui-kit";
import { Keyboard, KeyboardInput, ContentBox } from "../components";

import type {
  activate as activateMutation,
  activateVariables as activateMutationVariables
} from "../core.flow";

type Props = {
  onSubmit: (values: activateMutationVariables) => Promise<void>,
  handleCancelClick: () => void,
  loading: boolean,
  error: ?ApolloError
};

const ACTIVATE_MUTATION = gql`
  mutation activate($verificationCode: String!, $kioskId: String!) {
    activate(verificationCode: $verificationCode, kioskId: $kioskId) {
      stationClient {
        dockGroup {
          id
        }
      }
    }
  }
`;

const ActivateForm = ({
  handleCancelClick,
  loading,
  onSubmit,
  error
}: Props) => {
  const initialValues: { verificationCode: string } = {
    verificationCode: ""
  };
  const MAX_LENGTH = 6;
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        values,
        setFieldValue,
        submitForm,
        resetForm
      }: FormikProps<activateMutationVariables>) => {
        return (
          <Form data-testid="ActivateForm">
            {error && (
              <Alert variant="error" mb={4} data-testid="ActivateForm__Alert">
                {error.graphQLErrors.map(graphQLError => {
                  switch (graphQLError.code) {
                    case "ERR_INVALID_STATION_CLIENT_VERIFICATION_CODE":
                      return <div>Invalid verification code</div>;
                    default:
                      return <div>Someting wen't wrong, please try again</div>;
                  }
                })}
              </Alert>
            )}
            <Box mb={2}>
              <Label htmlFor="verificationCode" mb={4}>
                Verification code, {MAX_LENGTH} digits
              </Label>
              <KeyboardInput
                id="verificationCode"
                name="verificationCode"
                maxLength={MAX_LENGTH}
                textAlign="center"
                focused={values.verificationCode.length < MAX_LENGTH}
                mb={4}
              >
                {values.verificationCode}
              </KeyboardInput>
              <Keyboard
                mb={4}
                onButtonClick={key => {
                  const value =
                    key === "Backspace"
                      ? values.verificationCode.slice(0, -1)
                      : values.verificationCode + key;

                  if (value.length > MAX_LENGTH) {
                    return;
                  }

                  setFieldValue("verificationCode", value);
                }}
              />
            </Box>
            <ButtonGroup>
              <Button
                type="submit"
                loading={loading}
                jumbo
                data-testid="ActivateForm__SubmitButton"
              >
                Provision
              </Button>
              <Button
                onClick={handleCancelClick}
                disabled={loading}
                variant="secondary"
                jumbo
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Form>
        );
      }}
    />
  );
};

const ActivateFormWithMutation = ({
  handleCancelClick,
  kioskId,
  onSubmitSuccess,
  ...props
}: {
  kioskId: string,
  handleCancelClick: () => void,
  onSubmitSuccess: () => void
}) => {
  return (
    <Mutation mutation={ACTIVATE_MUTATION}>
      {(
        activate: MutationFunction<activateMutation, activateMutationVariables>,
        { data, loading, error }: MutationResult<activateMutation>
      ) => {
        if (!loading && data) {
          return (
            <Text fontSize={5} data-testid="Activate__Success">
              Great success{" "}
              <span role="img" aria-label="Done">
                👍
              </span>
            </Text>
          );
        }
        return (
          <ContentBox>
            <ActivateForm
              loading={loading}
              error={error}
              handleCancelClick={handleCancelClick}
              onSubmit={async values => {
                await activate({
                  variables: {
                    kioskId,
                    verificationCode: values.verificationCode
                  }
                });
                setTimeout(() => {
                  onSubmitSuccess();
                }, 1500);
              }}
            />
          </ContentBox>
        );
      }}
    </Mutation>
  );
};

export default ActivateFormWithMutation;
