// @flow

import * as React from "react";
import { useIntl, type IntlShape } from "react-intl";
import { ButtonGroup } from "@urbaninfrastructure/react-ui-kit";
import Button from "../Button";

import type { bootstrap_system } from "../../core.flow";

export type Props = {
  availableLocales: $PropertyType<bootstrap_system, "languageCodes">,
  onLanguageClick: (locale: string) => void
};

const localeMap = {
  en: "English",
  nb: "Norsk"
};

const LanguageSelector = ({ availableLocales, onLanguageClick }: Props) => {
  const intl: IntlShape = useIntl();
  if (!availableLocales) {
    return null;
  }
  return (
    <ButtonGroup>
      {availableLocales.map(locale => {
        return (
          <Button
            key={locale}
            variant={intl.locale !== locale ? "secondary" : null}
            onClick={() => onLanguageClick(locale)}
          >
            {localeMap[locale]}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default LanguageSelector;
