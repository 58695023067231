// @flow

import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { FormattedMessage } from "react-intl";
import {
  Loading,
  Flex,
  Box,
  Alert,
  BlockContent,
  H1,
  H2,
  Accordion
} from "../../components";
import { ABOUT_SCREEN_QUERY } from "./graphql";

import type {
  aboutScreen as aboutScreenQuery,
  aboutScreenVariables
} from "../../core.flow";

type Props = {
  systemId: string,
  kioskId: string,
  locale: string
};

export default function AboutScreen({ systemId, kioskId, locale }: Props) {
  const { data, loading } = useQuery<aboutScreenQuery, aboutScreenVariables>(
    ABOUT_SCREEN_QUERY,
    {
      variables: { systemId, locale },
      fetchPolicy: "cache-and-network"
    }
  );
  if (loading && (!data || !data.aboutScreen)) {
    return (
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Loading />
      </Flex>
    );
  }
  if (!data || !data.aboutScreen) {
    return <Alert>Could not load page</Alert>;
  }
  const { aboutScreen } = data;
  const { faqs } = aboutScreen;
  return (
    <Box data-testid="AboutScreen" mx={7} mt={7} textAlign="left">
      <H1 mb="sm" textAlign="center">
        {aboutScreen.heading}
      </H1>
      <Box mb="sm">
        <BlockContent
          kioskId={kioskId}
          systemId={systemId}
          locale={locale}
          blocks={aboutScreen.content}
        />
      </Box>
      {faqs && (
        <>
          <H2 mb="sm">
            <FormattedMessage
              id="AboutScreen.faqHeading"
              defaultMessage="Frequently asked questions"
            />
          </H2>
          <Accordion
            items={faqs.map(faq => ({
              id: faq._id,
              question: faq.question,
              answer: (
                <BlockContent
                  mb={0}
                  systemId={systemId}
                  locale={locale}
                  kioskId={kioskId}
                  blocks={faq.answer}
                />
              )
            }))}
          />
        </>
      )}
    </Box>
  );
}
