// @flow

import * as Sentry from "@sentry/browser";
import env from "../lib/env";

if (process.env.REACT_APP_SENTRY_DSN) {
  const ignoredErrorCodes = [
    "ERR_INVALID_USER_PIN_CODE_OR_PHONE_NUMBER",
    "ERR_INVALID_STATION_CLIENT_VERIFICATION_CODE",
    "ERR_NO_VEHICLES_AVAILABLE",
    "ERR_RECORD_NOT_FOUND"
  ];
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_GITSHA || "development",
    environment: env.name,
    debug: false,
    whitelistUrls: [env.hosts.staging, env.hosts.production],
    beforeSend(event, hint) {
      const error = hint.originalException;
      const shouldIgnore =
        error && ignoredErrorCodes.some(s => error.message.includes(s));
      if (shouldIgnore) {
        return null;
      }
      return event;
    }
  });
} else {
  console.log("process.env.REACT_APP_SENTRY_DSN not set, skipping Sentry init");
}
