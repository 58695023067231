// @flow

import gql from "graphql-tag";

export const DOCK_GROUP_QUERY = gql`
  query dockGroups($systemId: ID!) {
    dockGroups(systemId: $systemId) {
      id
      title
      coord {
        lat
        lng
      }
      availabilityInfo {
        availableVehicles
      }
    }
  }
`;
