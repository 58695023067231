// @flow

import * as React from "react";
import { Flex, Box, Button, Text } from "@urbaninfrastructure/react-ui-kit";
import { useWindowSize } from "../../lib/hooks";

type Props = {
  kioskId: string
};

// how long should the debug info be open after its opened?
const VISIBILITY_TIMEOUT_MS = 30 * 1000;

export default function DebugInfo({ kioskId }: Props) {
  const [isVisible, setIsVisible] = React.useState(false);
  const windowSize = useWindowSize();

  React.useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, VISIBILITY_TIMEOUT_MS);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isVisible]);

  if (!isVisible) {
    return (
      <Box
        data-testid="DebugInfo__Toggler"
        position="fixed"
        left={0}
        bottom={0}
        width={50}
        height={50}
        onClick={e => {
          if (e.detail === 3) {
            setIsVisible(true);
          }
        }}
      ></Box>
    );
  }

  return (
    <Flex
      data-testid="DebugInfo"
      position="fixed"
      left={0}
      bottom={0}
      p={3}
      bg="rgba(255,255,255,.2)"
      width={1}
      color="white"
      fontSize="12px"
      alignItems="center"
    >
      <Box flex={1} mr={2}>
        <Box>
          Version: {process.env.REACT_APP_VERSION}:{kioskId}
        </Box>
        <Box>
          Window size:{" "}
          <Text as="span" fontFamily="mono">
            {windowSize.innerWidth}x{windowSize.innerHeight}
          </Text>
        </Box>
      </Box>
      <Box mr={2}>
        <Button
          small
          onClick={() => {
            window.location.reload(true);
          }}
        >
          Reload
        </Button>
      </Box>
      <Box>
        <Button
          small
          onClick={() => {
            setIsVisible(false);
          }}
        >
          Close
        </Button>
      </Box>
    </Flex>
  );
}
