// @flow

import {
  getTheme as getUIKitTheme,
  presets,
  type Theme
} from "@urbaninfrastructure/react-ui-kit";

export default function getTheme(colourKey: ?string): Theme {
  const theme = getUIKitTheme(colourKey);

  const fontSizes = [24, 30, 37.5, 46.88, 58.59, 73.24, 91.55];
  // TODO: Try this out
  // const fontSizes = ["1vw", "2.5vw", "3vw", "4vw", "5vw", "6vw", "7vw"];

  const typoStyles = {
    xxs: {
      fontSize: fontSizes[0],
      lineHeight: 1.5,
      fontWeight: null
    },
    xs: {
      fontSize: fontSizes[1],
      lineHeight: 1.5,
      fontWeight: null
    },
    sm: {
      fontSize: fontSizes[2],
      lineHeight: 1.5,
      fontWeight: null
    },
    md: {
      fontSize: fontSizes[3],
      lineHeight: 1.5,
      fontWeight: "bold"
    },
    lg: {
      fontSize: fontSizes[4],
      lineHeight: 1.25,
      fontWeight: "bold"
    },
    xl: {
      fontSize: fontSizes[5],
      lineHeight: 1.25,
      fontWeight: "bold"
    },
    xxl: {
      fontSize: fontSizes[6],
      lineHeight: 1.25,
      fontWeight: "bold"
    }
  };

  const shadows = {
    ...theme.shadows,
    light: "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 7px rgba(0, 0, 0, 0.06);",
    heavy: "0px 0px 80px rgba(0, 0, 0, 0.08),0px 3px 10px rgba(0, 0, 0, 0.1);"
  };

  return { ...theme, ...presets.stationClient, fontSizes, typoStyles, shadows };
}
